import React, { useEffect, useState } from "react";
import { Canvas3D } from "../components/Canvas3D";
import { Layout } from "../components/Layout";
import CursorContextProvider from "../components/CursorContextProvider";
import { Cursor } from "../components/Cursor";
import * as AboutStyle from "../styles/About.module.css";
import { Landing } from "../components/Landing";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import GifVideo from "../components/GifVideo";
import { Link } from "gatsby";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import "../styles/style.css";
const About = () => {

  const [isFullscreen, setFullscreen] = useState(false);
  setTimeout(() => {
    setFullscreen(true);
  }, 3000);
  const transition = {
    duration: 1,
    ease: [0.54, 0.01, 0.61, 1],
  };

  return (
    <CursorContextProvider>
      <title>Manatee - Portfolio</title>
      <Cursor />
      <Layout>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            className={AboutStyle.aboutMain}
            key={"about"}
            initial={{ opacity: 0, y: 80 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1, ease: [0.37, 0, 0.63, 1] },
            }}
            exit={{ opacity: 0, y: 80 }}
          >
            <div className={AboutStyle.info}>
              <div className={AboutStyle.Text1}>
                <h4>EN</h4>
                <h2>Manatee</h2>
                <h3>Iridescent</h3>
              </div>
              <div className={AboutStyle.Text2}>
                <h4>ZH</h4>
                <h2>陈</h2>
                <h2>建桥</h2>
                <h3 style={{ paddingTop: "1rem" }}>红 色 肚 子 的 海 牛</h3>
                <div></div>
                <h4>
                  <br />
                  Wechat : Manatee0727
                </h4>
                <h4>Mail : manatee_jq@zju.edu.cn</h4>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </Layout>
    </CursorContextProvider>
  );
};

export default About;
